import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchAcDetail } from "@/api/finance";
import Pagination from "@/components/Pagination";
import "@/utils/global.js";
import { transCommonState, transRechargeType, transChannel, transChannelSvg } from "@/utils/util";
import { getToken } from "@/utils/cache";
import { downloadFun } from "@/utils/util";
export default {
  name: "rechargeList",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      searchForm: {
        page: 1,
        per_page: 20,
        id: ''
      },
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      totalNum: 0,
      admin: '',
      id: ''
    };
  },
  created: function created() {
    // this.getTableData();
    this.init();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      var id = this.id;
      fetchAcDetail(this.id, this.searchForm).then(function (response) {
        console.log(response);
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
        _this.totalNum = response.meta.total_money;
      }).catch(function (err) {
        _this.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
      this.tableLoading = false;
    },
    init: function init() {
      // 获取明细
      if (this.$route.query && this.$route.query.id) {
        this.admin = this.$route.query.name;
        this.id = this.$route.query.id;
        this.getTableData();
      }
    },
    handlePageCurrent: function handlePageCurrent(val) {
      console.log(val);
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      console.log(val);
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    }
  }
};